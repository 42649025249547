import { API_HOST, NOTIFY_HOST } from "./config";

const URL = {
  // a
  ADD_PLAYLIST: `${API_HOST}api/device/activate`,

  // b
  // c
  COINBASE: `${API_HOST}payment/coinbase/charge/device`,
  CONTACTS: `${API_HOST}api/support`,
  CONTACT_US_THKY: `${NOTIFY_HOST}contact`,
  APP_DEVELOPMENT:`${NOTIFY_HOST}appdevelopment`,
  PARTNER_THKY:`${NOTIFY_HOST}partner`,
  REGISTRATION_THKY:`${NOTIFY_HOST}registration`,
  // d
  // e
  // f
  // g
  // h
  // i
  // j
  // k
  // l
  // m
  CUSTOM_APP: `${API_HOST}email/custom_app`,
  // n
  NEWS: `${API_HOST}news`,
  //test
  // o
  // p
  PACKAGES: `${API_HOST}api/packages`,
  PLATFORMS: `${API_HOST}api/platforms`,
  PARTNERSHIP: `${API_HOST}email/partnership`,
  // q
  // r
  REGISTER: `${API_HOST}provider/provider/register`,
  REGISTER_EMAIL: `${API_HOST}api/validate_email`,
  // s
  SEND_MESSAGE: `${API_HOST}email/clickup`,
  // t
  // u
  // v
  // w
  // x
  // y
  // z
};

export default URL;
