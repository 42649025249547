import Image from "next/image";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import URL from "../../configs/url";
import ICONS from "../../configs/icons";
import IMAGES from "../../configs/images";

import classNames from "./contacts.module.scss";

export default function ContactsDetails() {
  const router = useRouter();
  const id = Number(router?.query?.id);

  const [data, setData] = useState(null);

  useEffect(() => {
    if (id) {
      const apiUrl = `${URL.CONTACTS}?provider_id=${id}`;
      fetch(apiUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setData(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [id]);

  const myLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  return (
    <div className={classNames["page"]}>
      {data && (
        <>
          <header>
            <div className={classNames["header-contacts"]}>
              <div className={classNames["logo"]}>
                {data?.logo && (
                  <Image
                    loader={myLoader}
                    src={data?.logo}
                    width={200}
                    height={200}
                    alt=""
                    onError={() => {
                      setData((prev) => {
                        return {
                          ...prev,
                          logo: IMAGES.LOGO,
                        };
                      });
                    }}
                  />
                )}
              </div>
              <div className={classNames["desc"]}>
                {data?.brand_name && <h2>{data?.brand_name}</h2>}
                {data?.notes && (
                  <div className={classNames["title"]}>
                    <span
                      style={{
                        textAlign: "center",
                        paddingTop: 15,
                      }}
                    >
                      {data.notes}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </header>
          <div className={classNames["page-content"]}>
            <div className={classNames["card-contacts-list"]}>
              {data?.email && (
                <div className={classNames["card-contact"]}>
                  <span>{ICONS.EMAIL}</span>
                  <span className={classNames["desc"]}>
                    <a href={`mailto:${data.email}`}>{data.email}</a>
                  </span>
                </div>
              )}
              {/* 2 */}
              {data?.phone && (
                <div className={classNames["card-contact"]}>
                  <span>{ICONS.PHONE}</span>
                  <span className={classNames["desc"]}>
                    <a href={`tel:${data.phone}`}>{data.phone}</a>
                  </span>
                </div>
              )}
              {/* 3 */}
              {data?.web_page && (
                <div className={classNames["card-contact"]}>
                  <span>{ICONS.WEB}</span>
                  <span className={classNames["desc"]}>
                    <a href={data.web_page}>{data.web_page}</a>
                  </span>
                </div>
              )}

              {data?.telegram && (
                <div className={classNames["card-contact"]}>
                  <span>{ICONS.TELEGRAM_CONTACTS}</span>
                  <span className={classNames["desc"]}>
                    <a href={data.telegram}>Telegram</a>
                  </span>
                </div>
              )}
              {/* 4 */}
              {data?.join_telegram_group && (
                <div className={classNames["card-contact"]}>
                  <span>{ICONS.TELEGRAM_CONTACTS}</span>
                  <span className={classNames["desc"]}>
                    <a href={data.join_telegram_group}>Join Telegram Group</a>
                  </span>
                </div>
              )}
              {/* 5 */}
              {data?.whatsapp && (
                <div className={classNames["card-contact"]}>
                  <span>{ICONS.WHATSAPP_CONTACTS}</span>
                  <span className={classNames["desc"]}>
                    <a href={data.whatsapp}>WhatsApp</a>
                  </span>
                </div>
              )}
              {/* 6 */}
              {data?.join_whatsapp_group && (
                <div className={classNames["card-contact"]}>
                  <span>{ICONS.WHATSAPP_CONTACTS}</span>
                  <span className={classNames["desc"]}>
                    <a href={data.join_whatsapp_group}>Join WhatsApp Group</a>
                  </span>
                </div>
              )}
              {/* 7 */}
              {data?.facebook && (
                <div className={classNames["card-contact"]}>
                  <span>{ICONS.FACBOOK}</span>
                  <span className={classNames["desc"]}>
                    <a href={data.facebook}>Facebook</a>
                  </span>
                </div>
              )}
              {/* 8 */}
              {data?.tiktok && (
                <div className={classNames["card-contact"]}>
                  <span>{ICONS.TIKTOK}</span>
                  <span className={classNames["desc"]}>
                    <a href={data.tiktok}>TikTok</a>
                  </span>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
