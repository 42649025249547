import { useEffect, useState } from "react";
import styles from "../styles/loader-page.module.scss";

export default function LoaderPage() {
    const [show, setShow] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(false);
        }, 2000);

        return () => {
            clearTimeout(timer);
        };
    }, [show]);

    return (
        show && (
            <div className={styles["page"]}>
                <div className={styles["loader"]}></div>
            </div>
        )
    );
}
