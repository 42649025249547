import { useRouter } from "next/router";
import Image from "next/image";
import Link from "next/link";
import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";
import { setIsLightMode } from "../redux/slices";

import { Drawer } from "antd";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";

import IMAGES from "../configs/images";
import PATHS from "../configs/paths";

import LanguageSwitcher from "./LanguageSwitcher";

import styles from "../styles/navbar.module.scss";

export default function Navbar({ activeLink }) {
    const router = useRouter();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { isLightMode } = useSelector((state) => state.appSlices);

    const [active, setActive] = useState(PATHS.HOME);

    const [openMenuDrawer, setOpenMenuDrawer] = useState(false);

    const changeMode = () => {
        dispatch(setIsLightMode(!isLightMode));
    };

    const onClickSignIn = () => {
        setOpenMenuDrawer(false);
    };

    const onClickLogo = () => {
        router.push("/");

        setOpenMenuDrawer(false);
    };

    const onClickRegister = () => {
        router.push(`/register`);

        setOpenMenuDrawer(false);
    };

    const closeMenu = () => {
        setOpenMenuDrawer(false);
    };

    const renderRoutes = () => {
        return (
            <>
                <div className={styles["nav-items"]}>
                    <Link
                        href="/about"
                        scroll={false}
                        onClick={closeMenu}
                        className={`${styles["route"]} ${
                            active === PATHS.ABOUT ? styles["active"] : ""
                        }`}
                    >
                        {t("about")}
                    </Link>

                    <Link
                        href="/#pricing"
                        onClick={closeMenu}
                        className={`${styles["route"]} ${
                            active === PATHS.PRICING ? styles["active"] : ""
                        }`}
                    >
                        {t("pricing")}
                    </Link>

                    <Link
                        href="/#platforms"
                        onClick={closeMenu}
                        className={`${styles["route"]} ${
                            active === PATHS.PLATFORMS ? styles["active"] : ""
                        }`}
                    >
                        {t("platforms")}
                    </Link>

                    <Link
                        href="/#download"
                        onClick={closeMenu}
                        className={`${styles["route"]} ${
                            active === PATHS.DOWNLOAD ? styles["active"] : ""
                        }`}
                    >
                        {t("download")}
                    </Link>

                    <Link
                        href="/#features"
                        onClick={closeMenu}
                        className={`${styles["route"]} ${
                            active === PATHS.FEATURES ? styles["active"] : ""
                        }`}
                    >
                        {t("features")}
                    </Link>

                    <Link
                        href="/#contact-us"
                        onClick={closeMenu}
                        className={`${styles["route"]} ${
                            active === PATHS.CONTACT ? styles["active"] : ""
                        }`}
                    >
                        {t("contact_us")}
                    </Link>
                </div>

                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                    }}
                >
                    <div className={styles["buttons"]}>
                        <div className={styles["button"]} onClick={onClickSignIn}>
                            <Link href={`${process.env.PANEL_HOST}#/login?signin`} target="_blank">
                                {t("sign_in")}
                            </Link>
                        </div>

                        <div
                            className={`${styles["button"]} ${styles["sign-up"]} button-scale`}
                            onClick={onClickRegister}
                        >
                            {t("register")}
                        </div>
                    </div>
                    <div className={styles["leng-mode"]}>
                        <div style={{ width: "100px" }}>
                            <LanguageSwitcher />
                        </div>
                        <div onClick={changeMode} style={{ width: "32px", transition: "2s" }}>
                            <Image
                                src={isLightMode ? IMAGES.LIGHT.src : IMAGES.DARK.src}
                                alt="mode"
                                width={32}
                                height={32}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };

    useEffect(() => {
        setActive(activeLink);
    }, [activeLink]);

    useEffect(() => {
        setActive(router.asPath);
    }, [router.asPath]);

    return (
        <div className={styles["navbar"]} style={{ background: isLightMode ? "#000" : "#0b313b" }}>
            <Drawer
                placement="left"
                width={300}
                onClose={() => setOpenMenuDrawer(false)}
                open={openMenuDrawer}
                headerStyle={{ display: "none" }}
                bodyStyle={{
                    background: isLightMode ? "#121315" : "#073e4a",
                }}
            >
                <div className="close-button" onClick={() => setOpenMenuDrawer(false)}>
                    <CloseOutlined />
                </div>
                <div className="menu-logo">
                    <Image
                        src={IMAGES.LOGO}
                        alt="logo"
                        width={200}
                        height={200}
                        onClick={onClickLogo}
                    />
                </div>
                {renderRoutes()}
            </Drawer>

            <div className={styles["container"]}>
                <div className={styles["burger-icon"]} onClick={() => setOpenMenuDrawer(true)}>
                    <MenuOutlined />
                </div>

                <Image
                    src={IMAGES.LOGO}
                    alt="logo"
                    width={50}
                    height={"100%"}
                    onClick={onClickLogo}
                />

                <div className={styles["mobile-leng-mode"]}>
                    <div>
                        <LanguageSwitcher />
                    </div>
                    <div
                        onClick={changeMode}
                        style={{ width: "32px", transition: "2s", marginRight: "0px" }}
                    >
                        <Image
                            src={isLightMode ? IMAGES.LIGHT.src : IMAGES.DARK.src}
                            alt="mode"
                            width={32}
                            height={32}
                        />
                    </div>
                </div>

                <div className={styles["routes-block"]}>{renderRoutes()}</div>
            </div>
        </div>
    );
}
