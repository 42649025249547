import Link from "next/link";
import Image from "next/image";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { Tooltip } from "antd";

import { DOMAIN, WEB_HOST, VERSION, TELEGRAM } from "../configs/config";
import IMAGES from "../configs/images";
import style from "../styles/footer.module.scss";

export default function Footer() {
  const { t } = useTranslation();

  const { isLightMode } = useSelector((state) => state.appSlices);

  return (
    <div
      className={style["footer"]}
      style={{ background: isLightMode ? `#000` : `#0b313b` }}
    >
      <div className={style["container"]}>
        <div className={style["empty"]}></div>
        <div className={style["link-list"]}>
          <Link href="/privacy-policy" target="_blank">
            {t("privacy_policy")}
          </Link>
          <span style={{ marginLeft: "8px" }}>|</span>
          <Link href="/refund" target="_blank">
            {t("refund_policy")}
          </Link>
          <span style={{ marginLeft: "8px" }}>|</span>
          <Link href="/terms" target="_blank">
            {t("terms_of_usage")}
          </Link>
          <span style={{ marginLeft: "8px" }}>|</span>
          <Link href="/device-activation">{t("how_to_activate")}</Link>
          <span style={{ marginLeft: "8px" }}>|</span>
          <Link
            href="https://playlistgn.xtream.cloud/#/auth/device-login"
            target="_blank"
          >
            {t("generate_playlist")}
          </Link>
        </div>

        <div className={style["domain-block"]}>
          <Link href={WEB_HOST} target="_blank">
            {DOMAIN}
          </Link>
          <span>
            {" "}
            © {new Date().getFullYear()} | {t("all_rights_reserved")}
          </span>
          <p>
            {" "}
            {t("version")}: {VERSION}
          </p>
        </div>

        <div className={style["social-links"]}>
          <Tooltip title="Join community">
            <a
              href="https://t.me/xtream_cloudtv"
              target={"_blank"}
              style={{
                background: "#1a414e42",
                borderRadius: "50%",
                width: "47px",
                height: "47px",
              }}
            >
              <Image
                src={IMAGES.JOIN_COMMUNITY.src}
                alt="Join community"
                width={47}
                height={47}
              />
            </a>
          </Tooltip>
          <Tooltip title="Support">
            <a href="mailto:xcloudapps@gmail.com" target={"_blank"}>
              <Image
                src={IMAGES.MESSAGE.src}
                alt="Support"
                width={40}
                height={40}
              />
            </a>
          </Tooltip>

          <Tooltip title="Telegram">
            <a href={TELEGRAM} target={"_blank"}>
              <Image
                src={IMAGES.TELEGRAM.src}
                alt="Telegram"
                width={47}
                height={47}
              />
            </a>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
