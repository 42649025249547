export const parseDate = (date) => {
    const dateObj = new Date(date);
    let month = dateObj.getMonth() + 1;
    let day = dateObj.getDate();
    const year = dateObj.getFullYear();

    if (month < 10) month = `0${month}`;
    if (day < 10) day = `0${day}`;

    const output = `${day}.${month}.${year}`;

    return output;
};

export const scrollTop = () => {
    if (document.getElementById("appLayout")) {
        document.getElementById("appLayout").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
};

export const scrollBottom = () => {
    if (document.getElementById("appLayout")) {
        document.getElementById("appLayout").scrollTo({
            top: ((appLayout.scrollHeight) - 1170),
            behavior: "smooth",
        });
    }
};