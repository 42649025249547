import { Layout } from "antd";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";

import Navbar from "./Navbar";
import Footer from "./Footer";
import ContactsDetails from "../pages/contacts/[id]";
import PlaylistActivationPage from "../pages/playlist-activation/[key]";
import BuyActivation from "../pages/buy-activation/[key]";
import PaymentResult from "../pages/payment-result/index";

export default function LayoutComponent({ children, activeLink }) {
  const route = useRouter();
  const { isLightMode } = useSelector((state) => state.appSlices);

  useEffect(() => {
    const titlesAndSubtitles = document.querySelectorAll(".title, .subtitle");
    const textColor = isLightMode ? "#000" : "#eeebeb";
    for (var i = 0; i < titlesAndSubtitles.length; i++) {
      titlesAndSubtitles[i].style.color = textColor;
    }
    const textWrap = document.querySelectorAll(".text-wrap");
    for (var i = 0; i < textWrap.length; i++) {
      if (isLightMode) {
        textWrap[i].style.color = "#000";
      } else {
        textWrap[i].style.color = "#fff";
      }
    }

    const inputSuffix = document.querySelectorAll(".ant-input-suffixm");
    for (var i = 0; i < inputSuffix.length; i++) {
      if (isLightMode) {
        inputSuffix[i].style.color = "#000";
      } else {
        inputSuffix[i].style.color = "#fff";
      }
    }
    const inp = document.querySelectorAll("input,  textarea");
    for (var i = 0; i < inp.length; i++) {
      if (isLightMode) {
        inp[i].style.borderColor = "#aeb4b775";
        inp[i].style.color = "#000";
      } else {
        inp[i].style.borderColor = "#005364";
        inp[i].style.color = "#fff";
      }
    }
  }, [isLightMode, route]);

  switch (route.route) {
    case "/contacts/[id]":
      return <ContactsDetails />;
    case "/playlist-activation/[key]":
      return <PlaylistActivationPage />;
    case "/buy-activation/[key]":
      return <BuyActivation />;
    case "/payment-result":
      return <PaymentResult />;
    default:
      return (
        <Layout>
          <Layout.Content
            id="appLayout"
            className="app-layout"
            style={{
              background: isLightMode ? "#f8f9fa" : "#073e4a",
            }}
          >
            <header>
              <Navbar activeLink={activeLink} />
            </header>
            {/* <div style={{ minHeight: "calc(100vh - 82px)" }}> */}
            <main>{children}</main>
            {/* </div> */}
            <footer>
              <Footer />
            </footer>
          </Layout.Content>
        </Layout>
      );
  }
}
