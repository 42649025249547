import Image from "next/image";
import IMAGES from "./images";

const languageOptions = [
  {
    label: (
      <span className="label-lang">
        <Image src={IMAGES.EN.src} alt="en" width={25} height={23} /> En
      </span>
    ),
    value: "en",
  },
  {
    label: (
      <span className="label-lang">
        <Image src={IMAGES.PT.src} alt="pt" width={25} height={23} /> Pt
      </span>
    ),
    value: "pt",
  },
  {
    label: (
      <span className="label-lang">
        <Image src={IMAGES.SQ.src} alt="sq" width={25} height={23} /> Sq
      </span>
    ),
    value: "sq",
  },
  {
    label: (
      <span className="label-lang">
        <Image src={IMAGES.SW.src} alt="sw" width={25} height={23} /> Sw
      </span>
    ),
    value: "sw",
  },

  {
    label: (
      <span className="label-lang">
        <Image src={IMAGES.AM.src} alt="am" width={25} height={23} /> Am
      </span>
    ),
    value: "am",
  },
  {
    label: (
      <span className="label-lang">
        <Image src={IMAGES.OM.src} alt="om" width={25} height={23} /> Om
      </span>
    ),
    value: "om",
  },
  {
    label: (
      <span className="label-lang">
        <Image src={IMAGES.IG.src} alt="ig" width={25} height={23} /> Ig
      </span>
    ),
    value: "ig",
  },
  {
    label: (
      <span className="label-lang">
        <Image src={IMAGES.SO.src} alt="so" width={25} height={23} /> So
      </span>
    ),
    value: "so",
  },
  {
    label: (
      <span className="label-lang">
        <Image src={IMAGES.HA.src} alt="ha" width={25} height={23} /> Ha
      </span>
    ),
    value: "ha",
  },
  {
    label: (
      <span className="label-lang">
        <Image src={IMAGES.MN.src} alt="mn" width={25} height={23} /> Mn
      </span>
    ),
    value: "mn",
  },
  {
    label: (
      <span className="label-lang">
        <Image src={IMAGES.FU.src} alt="fu" width={25} height={23} /> Fu
      </span>
    ),
    value: "fu",
  },
  {
    label: (
      <span className="label-lang">
        <Image src={IMAGES.YO.src} alt="yo" width={25} height={23} /> Yo
      </span>
    ),
    value: "yo",
  },
];
export default languageOptions;
