export default function BuyActivationPackage({ data }) {
  return (
    <div className="pay-pakg">
      <div className="circle">
        <div></div>
      </div>
      <div className="info">
        <div>
          <span className="name">{data?.name}</span>
          {/* <span className="sale">-{data?.sale}%</span> */}
        </div>
        <div className="br-price">
          <span>R$ {data?.br_price}</span>
          {/* <del>R$ {data?.old_br_price}</del> */}
        </div>
      </div>
      <div className="usd">
        {/* <del>${data?.old_price}</del> */}
        <span>$ {data?.price}</span>
      </div>
    </div>
  );
}
