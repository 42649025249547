import { createSlice } from '@reduxjs/toolkit';

const slices = createSlice({
  name: 'appSlices',
  initialState: {
    isLightMode: true,
  },
  reducers: {
    setIsLightMode: (state, action) => {
        state.isLightMode = action.payload;
    },
  },
});

export const { setIsLightMode } = slices.actions;
export default slices.reducer;
