import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "./locales/en.json";
import ptTranslation from "./locales/pt.json";
import sqTranslation from "./locales/sq.json";

import swTranslation from "./locales/sw.json";
import amTranslation from "./locales/am.json";
import omTranslation from "./locales/om.json";
import igTranslation from "./locales/ig.json";
import soTranslation from "./locales/so.json";
import haTranslation from "./locales/ha.json";
import mnTranslation from "./locales/mn.json";
import fuTranslation from "./locales/fu.json";
import yoTranslation from "./locales/yo.json";

const resources = {
  en: {
    translation: enTranslation,
  },
  pt: {
    translation: ptTranslation,
  },
  sq: {
    translation: sqTranslation,
  },
  sw: {
    translation: swTranslation,
  },
  am: {
    translation: amTranslation,
  },
  om: {
    translation: omTranslation,
  },
  ig: {
    translation: igTranslation,
  },
  so: {
    translation: soTranslation,
  },
  ha: {
    translation: haTranslation,
  },
  mn: {
    translation: mnTranslation,
  },
  fu: {
    translation: fuTranslation,
  },
  yo: {
    translation: yoTranslation,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // Default language
  fallbackLng: "en", // Fallback language
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
