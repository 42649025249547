import { Select } from "antd";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

import languageOptions from "../configs/languages";
import { useEffect, useState } from "react";

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const router = useRouter();

  const [active, setActive] = useState();

  const { locales, locale: activeLocale } = router;

  useEffect(()=>{
    setActive(activeLocale);
    i18n.changeLanguage(activeLocale);

  },[activeLocale])

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    const pathname = router.pathname;
    const { query } = router;

    router.push({ pathname, query }, undefined, { locale: lng });
  };

  return (
    <div>
      <Select
        onChange={(lng)=>changeLanguage(lng)}
        optionLabelProp="label"
        className="language-select"
        value={active}
      >
        {languageOptions.map((lang) => (
          <Select.Option key={lang.value} value={lang.value} label={lang.label}>
            {lang.label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export default LanguageSwitcher;
