import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Form, Input, Button } from "antd";
import { scrollBottom } from "../../configs";
import URL from "../../configs/url";
import style from "./playlist.module.scss";

export default function UrlPlaylist({ activTabKey, deviceKey }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState("");

  const handleBlur = (event) => {
    scrollBottom();
  };

  const onFinish = (values) => {
    setLoading(true);

    const body = {
      device_key: deviceKey,
    };

    if (values.playlist) {
      body.playlist = values.playlist;
    }
    if (values.host && values.username && values.password) {
      let host = values.host;

      if (host.endsWith("/")) {
        host = host.slice(0, -1);
      }
      let playlist = `${host}/get.php?username=${values.username}&password=${values.password}`;

      if (playlist) {
        body.playlist = playlist;
      }
    }
    try {
      fetch(URL.ADD_PLAYLIST, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.status !== 200) {
            return res.json().then((err) => {
              throw new Error(err);
            });
          }
          return res.json();
        })
        .then((res) => {
          form.resetFields();
          setLoading(false);
          setShowError("");
        })
        .catch((err) => {
          setLoading(false);
          setShowError(err.message);
        });
    } catch (err) {}
  };

  const onValuesChange = () => {
    setShowError("");
  };
  return (
    <>
      <Form
        name="playlist"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        className={style["form-items"]}
      >
        {activTabKey == "url" ? (
          <Form.Item
            name="playlist"
            rules={[
              {
                required: true,
                message: t("input_playlist_URL"),
              },
              {
                type: "url",
                message: t("input_no_valid_playlist_URL"),
              },
              {
                pattern: /^(http|https):\/\/[^ "]+$/,
                message: t("the_url_must_start_with_http_or_https"),
              },
            ]}
          >
            <Input placeholder={t("playlist_URL")} onBlur={handleBlur} />
          </Form.Item>
        ) : (
          <>
            <Form.Item
              name="host"
              rules={[
                {
                  required: true,
                  message: t("input_host"),
                },

                {
                  type: "url",
                  message: t("input_no_valid_playlist_URL"),
                },

                {
                  pattern: /^(http|https):\/\/[^ "]+$/,
                  message: t("the_url_must_start_with_http_or_https"),
                },
              ]}
            >
              <Input placeholder={t("host")} onBlur={handleBlur} />
            </Form.Item>

            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: t("input_username"),
                },
              ]}
            >
              <Input placeholder={t("username")} onBlur={handleBlur} />
            </Form.Item>

            <Form.Item
              name="password"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: t("please_input_your_password"),
                },
              ]}
            >
              <Input placeholder={t("password")} />
            </Form.Item>
          </>
        )}

        <Form.Item>
          <Button
            className={style["start-btn"]}
            loading={loading}
            onClick={() => form.submit()}
          >
            {t("start_free_trial")}
          </Button>
        </Form.Item>
      </Form>
      <div
        style={{
          color: "#ff4d4f",
          marginTop: "-10px",
        }}
      >
        {showError}
      </div>
    </>
  );
}
