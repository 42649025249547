import React from "react";
import Link from "next/link";

import Image from "next/image";
import { useTranslation } from "react-i18next";

import { Button } from "antd";

import { TELEGRAM } from "../../configs/config";

import ICONS from "../../configs/icons";

import IMAGES from "../../configs/images";
import style from "./style.module.scss";

export default function PaymentResult() {
  const { t } = useTranslation();

  return (
    <section className={style["page"]}>
      <div className={style["container"]}>
        <div className={style["wrap"]}>
          <div className={style["logo"]}>
            <Image src={IMAGES.DONE_DUOTONE} alt="done" />
          </div>
          <h2 className={style["title"]}>{t("thank_you")}</h2>
          <h2 className={style["sub-title"]}>{t("for_choosing_us")}</h2>
          <div className={style["part-txt"]}>
            <p className={style["txt"]}>
              {t(
                "if_you_have_any_issues_or_questions_please_get_in_touch_with_us"
              )}
              {/* {t("please_reopen_the_app_to_start_using_our_services")} */}
            </p>
          </div>
          <div className={style["btn-social"]}>
            <Link href={TELEGRAM} target="_blank">
              <Button icon={ICONS.TELEGRAM} className="btn-become-partner">
                {t("contact_on_telegram")}{" "}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
