const PATHS = {
  // a
  APP_FEATURES: "/#app-features",
  ABOUT: "/#about",
  // b
  BRANDING: "/#branding",
  BECOME_PARTNER: "/become-partner",
  BRANDED_APP: "/#branded-app",
  // c
  CONTACT: "/#contact",
  // d
  DEVICE_ACTIVATION: "/device-activation",
  DOWNLOAD: "/#download",
  // e
  // f
  FEATURES: "/#features",
  // g
  // h
  HOME: "/#home",
  // i
  INSTALLATION_GUIDE: "/installation-guide",
  // j
  // k
  // l
  LATEST_NEWS: "/#news",
  // m
  // n
  NEWS: "/news",
  // o
  // p
  PRICING: "/#pricing",
  PLATFORMS: "/#platforms",
  PRIVACY_POLICY: "/privacy-policy",
  PRIVACY_POLICY_FOOTER: "/#privacy-policy",
  // q
  // r
  REGISTER: "/register",
  RESELLER_PANEL: "/#reseller-panel",
  // s
  SERVER_DNS: "/#server-dns",
  // t
  // u
  // v
  // w
  // x
  // y
  // z
};

export default PATHS;
